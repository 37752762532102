import { z } from "zod"

const clerkErrorsSchema = z.array(
  z.object({
    message: z.string(),
    longMessage: z.string().optional(),
    meta: z
      .object({
        paramName: z.string(),
      })
      .optional(),
  })
)

const clerkErrorSchema = z.object({
  errors: clerkErrorsSchema,
})

type ClerkError = z.infer<typeof clerkErrorSchema>

export const isClerkError = (err: unknown): err is ClerkError => {
  const isClerkError = clerkErrorSchema.safeParse(err)
  return isClerkError.success
}
